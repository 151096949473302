/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect } from 'react';
import { ReservationService } from '../../../services';
import { Alert, Button, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { ITicketInfoDto } from '../../../clients/services';
import { ConcertName } from '../../../components/ConcertPreview/ConcertName';
import { Link } from 'react-router-dom';
import { PersonTicketInfo } from './PersonTicketInfo';
import { DateTimeUtils } from '../../../helpers';

const service = new ReservationService();

type TicketProps = {
    orderId: number;
    guid: string;
};

export const TicketCheck: React.FC<TicketProps> = (props) => {
    const [order, setOrder] = useState<ITicketInfoDto | null>(null);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const loadTicketInfo = async () => {
        setError(false);
        setLoading(true);
        try {
            const loadedOrder = await service.getTicketInfo(props.orderId, props.guid);
            setOrder(loadedOrder);
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
            setError(true);
        }
    };

    useEffect(() => {
        const loadData = async () => {
            await loadTicketInfo();
        };
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {loading && <LinearProgress></LinearProgress>}
            {!loading && error && (
                <Alert severity="error">
                    Das Ticket konnte wegen einem Netzwerkproblem nicht überprüft werden!
                    <br />{' '}
                    <Button variant="contained" onClick={loadTicketInfo}>
                        Nochmals versuchen.
                    </Button>
                </Alert>
            )}

            {!loading && order && (
                <>
                    {order.payed && (
                        <Alert severity="success" style={{ padding: 10, margin: 5 }}>
                            Bezahlt
                        </Alert>
                    )}
                    {!order.payed && (
                        <Alert severity="error" style={{ padding: 10, margin: 5 }}>
                            Bezahlung nicht bestätigt!
                        </Alert>
                    )}
                    <Paper style={{ backgroundColor: '#F9F9F9', padding: 10, margin: 5 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12} style={{ padding: 10 }}>
                                        <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                                            {order.concert.title}
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                color: 'black',
                                                marginBottom: 5,
                                            }}
                                        >
                                            <ConcertName {...order.concert} />
                                        </Typography>
                                        Datum: {DateTimeUtils.formatLong(order.concert.date)}
                                        <br />
                                        Konzertbeginn: {order.concert.start} Uhr
                                        <br />
                                        Türöffnung: {order.concert.doors} Uhr
                                        <br />
                                        <br />
                                        <Paper style={{ padding: 10, margin: 5 }}>
                                            <PersonTicketInfo order={order} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper style={{ backgroundColor: '#F9F9F9', padding: 10, margin: 5 }}>
                        <Link
                            to={`/ticket/${order.concert.id}/${order.guid}`}
                            color="inherit"
                            style={{ textDecoration: 'none' }}
                        >
                            <Button variant="contained">Ticket</Button>
                        </Link>

                        <Link
                            to={`/my-seat/${order.concert.id}/${order.guid}`}
                            color="inherit"
                            style={{ textDecoration: 'none' }}
                        >
                            <Button variant="contained">Sitzplan</Button>
                        </Link>
                    </Paper>
                </>
            )}
        </>
    );
};
