import React, { useState, useEffect } from 'react';
import { ReservationService } from '../../../services';
import QRCode from 'react-qr-code';
import { ITicketInfoDto } from '../../../clients/services';
import { Alert, Button, Grid, LinearProgress, Paper, Typography } from '@mui/material';

import { RestrictionMessage } from '../parts/RestrictionMessage';
import { ConcertName } from '../../../components/ConcertPreview/ConcertName';
import { Link } from 'react-router-dom';
import { PersonTicketInfo } from './PersonTicketInfo';
import { DateTimeUtils } from '../../../helpers';

const service = new ReservationService();

type TicketProps = {
    concertId: number;
    guid: string;
    confirmEmail?: boolean;
    hidePlanButton?: boolean;
};

export const Ticket: React.FC<TicketProps> = (props) => {
    const [order, setOrder] = useState<ITicketInfoDto | null>(null);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const loadTicketInfo = async () => {
        setError(false);
        setLoading(true);
        try {
            const order = await service.getTicketInfo(props.concertId, props.guid);
            if (order) {
                setOrder(order);
            } else {
                setError(true);
            }
        } catch (e) {
            console.log(e);
            setError(true);
            setLoading(false);
            return;
        }

        if (props.confirmEmail) {
            try {
                await service.getOrder(props.concertId, props.guid, props.confirmEmail);
            } catch (e) {
                console.log(e);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        loadTicketInfo();
    }, []);

    return (
        <>
            {loading && <LinearProgress></LinearProgress>}
            {!loading && error && (
                <Alert severity="error" sx={{ displayPrint: 'none' }}>
                    Das Ticket konnte nicht geladen werden!
                    <br />{' '}
                    <Button variant="contained" onClick={loadTicketInfo}>
                        Nochmals versuchen.
                    </Button>
                </Alert>
            )}
            {!loading && order && (
                <>
                    {order.payed && (
                        <Alert severity="success" style={{ padding: 10, margin: 5 }} sx={{ displayPrint: 'none' }}>
                            Die Bezahlung wurde bereits bestätigt.
                        </Alert>
                    )}
                    <Paper sx={{ minidthPrint: 800 }} style={{ backgroundColor: '#F9F9F9' }}>
                        <Grid container style={{ textAlign: 'center' }}>
                            <Grid item xs={12} md={4}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <img
                                            src="/images/header_1.png"
                                            alt="Martin Meier Presents"
                                            style={{ width: '100%' }}
                                        ></img>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <QRCode value={order.ticketCheckUrl} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Grid container>
                                    <Grid item xs={12} style={{ marginTop: 20, textAlign: 'center' }}>
                                        <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
                                            {order.concert.title}
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: 20,
                                                fontWeight: 'bold',
                                                color: 'black',
                                                marginBottom: 20,
                                            }}
                                        >
                                            <ConcertName {...order.concert} />
                                        </Typography>
                                        {order.concert.date && (
                                            <>
                                                Datum: {DateTimeUtils.formatLong(order.concert.date)}
                                                <br />
                                            </>
                                        )}
                                        {order.concert.start && (
                                            <>
                                                Konzertbeginn: {order.concert.start} Uhr
                                                <br />
                                            </>
                                        )}
                                        {order.concert.doors && (
                                            <>
                                                Türöffnung: {order.concert.doors} Uhr
                                                <br />
                                            </>
                                        )}
                                        <br />
                                        <Paper style={{ padding: 10, margin: 5 }}>
                                            <PersonTicketInfo order={order} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <RestrictionMessage concert={order.concert} />

                    {!order.payed && (
                        <Alert severity="warning" style={{ padding: 10, margin: 5 }}>
                            Das Ticket kann bereits gedruckt werden,{' '}
                            <b>der QR-Code wird aber erst gültig, wenn die Zahlung eingetroffen und bestätigt</b> wurde.{' '}
                        </Alert>
                    )}
                    <Paper sx={{ displayPrint: 'none' }} style={{ backgroundColor: '#F9F9F9', padding: 10, margin: 5 }}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                window.print();
                            }}
                            style={{ textDecoration: 'none' }}
                        >
                            Drucken
                        </Button>
                        {!props.hidePlanButton && (
                            <Link
                                to={`/my-seat/${order.concert.id}/${order.guid}`}
                                color="inherit"
                                style={{ textDecoration: 'none' }}
                            >
                                <Button variant="contained">Sitzplan</Button>
                            </Link>
                        )}
                    </Paper>
                </>
            )}
        </>
    );
};
