import parse from 'html-react-parser';
import { CommentDtoCommentType, ICommentDto, IConcertDto } from '../../clients/services';
import { DateTimeUtils } from '../../helpers';

export const ConcertMessage = (comment: ICommentDto) => (
    <span key={comment.message} style={{ fontSize: 12 }}>
        * {parse(comment.message)}
    </span>
);

export const ConcertMessages = (props: { concert: IConcertDto }) => {
    if (!props.concert.date || props.concert.postponedToUnknownDate || props.concert.concertCancelled) {
        const noDateComment =
            props.concert.comments?.filter(
                (c) =>
                    c.commentType === CommentDtoCommentType.NoDate ||
                    c.commentType === CommentDtoCommentType.Canceled ||
                    c.commentType === CommentDtoCommentType.Postponed,
            ) ?? [];

        if (noDateComment.length > 0) {
            return (
                <>
                    {noDateComment.map((m) => (
                        <ConcertMessage key={m.message} {...m}></ConcertMessage>
                    ))}
                </>
            );
        }

        if (props.concert.postponedToUnknownDate) {
            return <ConcertMessage {...{ message: 'Das Konzert wurde verschoben.' }} />;
        }

        if (props.concert.concertCancelled) {
            return <ConcertMessage {...{ message: 'Das Konzert wurde abgesagt.' }} />;
        }

        return <ConcertMessage {...{ message: 'Das Datum wird in den nächstent Tagen bekanntgegeben.' }} />;
    }

    const ticketSaleComment =
        props.concert.comments?.filter((c) => c.commentType === CommentDtoCommentType.TicketSale) ?? [];

    if (ticketSaleComment.length > 0) {
        return (
            <>
                {ticketSaleComment.map((m, k) => (
                    <>
                        <ConcertMessage key={m.message + k} {...m}></ConcertMessage>
                        <br />
                    </>
                ))}
            </>
        );
    }

    if (!props.concert.ticketSale) {
        return <ConcertMessage {...{ message: 'Kein Onlineverkauf für dieses Konzert' }} />;
    }

    if (DateTimeUtils.isInThePast(props.concert.date.endOf('day'))) {
        return <ConcertMessage {...{ message: 'Das Konzert ist bereites vorbei' }} />;
    }

    return <></>;
};
