import { useEffect, useState } from 'react';
import { IConcertSeriesDto, ConcertDto, IConcertDto } from '../../clients/services';
import { MainContent } from '../../components';
import { ReservationService } from '../../services';
import Grid from '@mui/material/Grid';
import { Alert, LinearProgress } from '@mui/material';
import { DateTime } from 'luxon';
import { correctPic } from '../../helpers/correct-pic';
import { ConcertData } from '../../components/ConcertPreview/ConcertData';

import { Link } from 'react-router-dom';
import { ConcertMessages } from '../../components/ConcertPreview/ConcertMessages';
import { DateTimeUtils } from '../../helpers';

const service = new ReservationService();

export const Concerts = () => {
    const [error, setError] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [wasLoaded, setWasLoaded] = useState<boolean>(false);
    const [series, setSeries] = useState<IConcertSeriesDto[]>([]);

    const loadConcerts = async () => {
        setLoading(true);
        try {
            const loadedSeries = await service.getConcertSeriesForTicketSales();

            for (const serie of loadedSeries) {
                const concertForSeries = await service.getConcertsForSeries(serie.id);
                serie.concerts = concertForSeries.filter((c) => !c.hide).map((c) => new ConcertDto(c));
            }

            setWasLoaded(true);
            setSeries(loadedSeries);
        } catch (e) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const loadData = async () => {
            await loadConcerts();
        };
        loadData();
    }, []);

    const TicketImage = (props: { soldOut: boolean }) => (
        <>
            {props.soldOut ? (
                <img
                    style={{
                        width: '140px',
                        marginRight: '10px',
                        verticalAlign: 'middle',
                    }}
                    src="/images/tickets-sold-out.png"
                    alt="Tickets"
                />
            ) : (
                <img
                    style={{
                        width: '140px',
                        marginRight: '10px',
                        verticalAlign: 'middle',
                    }}
                    src="/images/tickets.png"
                    alt="Tickets"
                />
            )}
        </>
    );

    const ConcertContainer = (props: { concert: IConcertDto; concertSeries?: IConcertSeriesDto }) => {
        const [hoverClass, setHoverClass] = useState('');

        const hasTicketSale =
            props.concert.ticketSale &&
            !props.concert.concertCancelled &&
            props.concert.date &&
            DateTimeUtils.isInTheFuture(props.concert.date.endOf('day'));
        return (
            <Grid
                container
                className={'concert-container ' + hoverClass}
                onMouseOver={() => {
                    if (hasTicketSale) {
                        console.log('enter');
                        setHoverClass('hovered');
                    }
                }}
                onMouseOut={() => {
                    console.log('leave');
                    if (hasTicketSale) {
                        setHoverClass('');
                    }
                }}
                style={{ margin: 5 }}
            >
                {hasTicketSale ? (
                    <Link to={'/reservation/' + props.concert.id} className="ticket-link" title="Tickets bestellen...">
                        <Grid item xs={3} className={'ticket-link'}>
                            <TicketImage soldOut={props.concert.soldOut ?? false} />
                        </Grid>
                    </Link>
                ) : (
                    <>
                        {props.concert.soldOut ? (
                            <Grid item xs={3} className={'ticket-link'}>
                                <TicketImage soldOut={true} />
                            </Grid>
                        ) : (
                            <Grid item xs={3}></Grid>
                        )}
                    </>
                )}

                <Grid item xs={9}>
                    {hasTicketSale ? (
                        <Link
                            to={'/reservation/' + props.concert.id}
                            className="ticket-link"
                            title="Tickets bestellen..."
                        >
                            <b>
                                <ConcertData
                                    concert={props.concert}
                                    concertSeries={props.concertSeries}
                                    showConcertTitle
                                    showReductions
                                />
                            </b>
                        </Link>
                    ) : (
                        <b>
                            <ConcertData
                                concert={props.concert}
                                concertSeries={props.concertSeries}
                                showConcertTitle
                                showReductions
                            />
                        </b>
                    )}

                    <ConcertMessages concert={props.concert} />
                </Grid>
            </Grid>
        );
    };

    return (
        <MainContent>
            {
                <>
                    {
                        <>
                            <h1>Online Ticket-Reservation</h1>
                            {series?.length === 0 && series?.some((s) => s.concerts && s.concerts.length > 0) ? (
                                <p style={{ marginBottom: '20px' }}>
                                    Momentan ist kein Konzert für den Ticketverkauf wählbar.
                                </p>
                            ) : (
                                <>
                                    <p style={{ marginBottom: '20px' }}>
                                        Bitte klicke auf das gewünschte Konzert um deine Tickets zu reservieren.
                                    </p>
                                    {isLoading ? (
                                        <LinearProgress />
                                    ) : (
                                        <>
                                            {series.length > 0 ? (
                                                series.map((s) => (
                                                    <div
                                                        key={'series' + s.id}
                                                        className="frame_1"
                                                        style={{ textAlign: 'left', marginTop: 20 }}
                                                    >
                                                        <div className="frame_2 first_frame">
                                                            <p>
                                                                <b>{s.title}</b>
                                                            </p>
                                                        </div>

                                                        <p>&nbsp;</p>
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                {s.picture && s.picture.url && (
                                                                    <img
                                                                        className="image_border"
                                                                        style={{
                                                                            width: '300px',
                                                                            marginRight: '10px',
                                                                            verticalAlign: 'right',
                                                                        }}
                                                                        src={correctPic(s.picture.url)}
                                                                        alt={s.picture.title}
                                                                    />
                                                                )}
                                                            </Grid>
                                                            <Grid xs={8}>
                                                                {s.concerts
                                                                    ?.sort(
                                                                        (a, b) =>
                                                                            (a.date?.toMillis() ?? 0) -
                                                                            (b.date?.toMillis() ?? 0),
                                                                    )
                                                                    .map((concert) => (
                                                                        <div key={'concert' + concert.id}>
                                                                            {concert.ticketSale &&
                                                                            !concert.concertCancelled &&
                                                                            concert.date &&
                                                                            DateTimeUtils.isInTheFuture(
                                                                                concert.date.endOf('day'),
                                                                            ) ? (
                                                                                <div
                                                                                    style={{
                                                                                        marginBottom: 10,
                                                                                    }}
                                                                                >
                                                                                    <ConcertContainer
                                                                                        concert={concert}
                                                                                        concertSeries={s}
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    style={{
                                                                                        marginBottom: 10,
                                                                                    }}
                                                                                >
                                                                                    <ConcertContainer
                                                                                        key={concert.id}
                                                                                        concert={concert}
                                                                                        concertSeries={s}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                            </Grid>
                                                        </Grid>

                                                        {s.ticketSaleStart && (
                                                            <>
                                                                <br />
                                                                {s.ticketSaleStart > DateTime.now() ? (
                                                                    <p>
                                                                        Der Vorverkauf beginnt am{' '}
                                                                        {DateTimeUtils.formatLong(s.ticketSaleStart)}
                                                                        <br />
                                                                    </p>
                                                                ) : (
                                                                    <p>
                                                                        Der Vorverkauf läuft.
                                                                        <br />
                                                                    </p>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                <>
                                                    {error ? (
                                                        <Alert severity="error" title="Fehler">
                                                            Die Konzertinformationen konnten nicht geladen werden.
                                                        </Alert>
                                                    ) : (
                                                        <Alert severity="info">
                                                            Im Moment ist keine Ticketreservation erfassst.
                                                        </Alert>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    }
                    <hr />
                </>
            }
        </MainContent>
    );
};
