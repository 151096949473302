import { IConcertSeriesDto } from '../../clients/services';
import { DateTimeUtils } from '../../helpers';
import ArtistLink from './ArtistLink';
import Comment from './Comment';
import { ConcertName } from './ConcertName';

export const ConcertDates = (props: IConcertSeriesDto) => {
    return (
        <div className="frame_1">
            <div className="frame_2 first_frame" style={{ backgroundImage: 'none' }}>
                <p>
                    <span className="color1">Konzerte: </span> <b>{props.title}</b>
                </p>
            </div>
            <p>&nbsp;</p>
            <p>
                <b>
                    {props.concerts
                        ?.filter((c) => !c.hide)
                        .map((c, key) => (
                            <span key={key}>
                                {!c.postponedToUnknownDate ? (
                                    <>
                                        {c.originalDate ? (
                                            <>
                                                <span className="color2">{DateTimeUtils.formatLong(c.date)}</span>{' '}
                                                (anstatt{' '}
                                                <span style={{ textDecoration: 'line-through' }}>
                                                    {DateTimeUtils.formatLong(c.originalDate)}
                                                </span>
                                                )
                                            </>
                                        ) : (
                                            <span className="color1">{DateTimeUtils.formatLong(c.date)}</span>
                                        )}{' '}
                                        - <ConcertName {...c} />
                                        <br />
                                    </>
                                ) : (
                                    <>
                                        <span className="color1" style={{ textDecoration: 'line-through' }}>
                                            <>{DateTimeUtils.formatLong(c.originalDate ?? c.date)}</>
                                        </span>{' '}
                                        <span className="color2"> VERSCHOBEN</span> - <ConcertName {...c} />
                                        <br />
                                    </>
                                )}
                            </span>
                        ))}
                </b>
            </p>
            {props.comments?.map((c) => (
                <Comment {...c} key={c.message} />
            ))}
            {props.links?.map((l) => (
                <ArtistLink key={l.url} {...l} />
            ))}
        </div>
    );
};

export default ConcertDates;
