import { format } from 'date-fns';
import { de } from 'date-fns/locale';

export class DateFormat {
    public static readonly Long = 'cccc, dd. LLLL yyyy';
    public static readonly Store = 'yyyy-MM-dd-HH-mm';
    public static readonly DbDateTime = 'yyyy-MM-dd HH:mm:ss';
    public static readonly DbDate = 'yyyy-MM-dd';

    public static formatLongGerman(date: Date): string {
        return format(date, DateFormat.Long, { locale: de });
    }
}
