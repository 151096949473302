import { DateTime } from 'luxon';
import { NavLink } from 'react-router-dom';
import { CommentDto } from '../../clients/services';
import { PageRoutes } from '../../page-routes';
import parse from 'html-react-parser';
import { DateTimeUtils } from '../../helpers';

interface Props {
    comment?: CommentDto;
    ticketSaleStart?: DateTime;
}
export const TicketReservationLink = (props: Props) => {
    if (!props.comment && !props.ticketSaleStart) {
        return <></>;
    }

    return (
        <div className="frame_2 first_frame" style={{ overflow: 'hidden', backgroundImage: 'none' }}>
            <NavLink to={PageRoutes.Tickets}>
                <div style={{ float: 'left', marginRight: 20 }}>
                    <img style={{ width: 80, textAlign: 'center' }} src="/images/tickets.png" alt="Tickets" />
                </div>

                <div style={{ overflow: 'hidden', paddingLeft: 15 }}>
                    <div>Online-Ticket-Reservierung</div>

                    {props.comment ? (
                        <div style={{ color: 'white' }}>
                            {parse(props.comment.message)}
                            <br />
                        </div>
                    ) : (
                        <>
                            {props.ticketSaleStart && DateTimeUtils.isInThePast(props.ticketSaleStart) && (
                                <div style={{ color: 'white' }}>
                                    Reserviere jetzt dein Ticket!
                                    <br />
                                </div>
                            )}
                            {props.ticketSaleStart && DateTimeUtils.isInTheFuture(props.ticketSaleStart) && (
                                <div style={{ color: 'white' }}>
                                    Der Vorverkauf beginnt am {DateTimeUtils.formatLong(props.ticketSaleStart)}.
                                    <br />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </NavLink>
        </div>
    );
};

export default TicketReservationLink;
