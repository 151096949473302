import React, { useState, useEffect } from 'react';
import { ReservationService } from '../../../services';
import { IReservationInfoDto } from '../../../clients/services';
import { ISeatDisplayInfo, IBlockDisplayInfo } from '../services/plan-info';
import { SeatRestriction } from '../models/seat-restriction';
import { SeatState } from '../models/seat-state';
import { PlanFactory } from '../services/plan-factory';
import { RestrictionChecker } from '../services/restriction-checker';
import { ReservationStepper } from './ReservationStepper';
import { Plan } from '../plan';
import { MainContent } from '../../../components/MainContent';
import { Ticket } from '../views/Ticket';
import { Alert, Button, LinearProgress } from '@mui/material';
import { RootDiv } from '../styles';
import { ISeatSelection } from '../services/seat-selection';
import { DateFormat } from '../../../helpers';

const service = new ReservationService();
type ReservationProps = {
    concertId: number;
    guid: string;
    verifyEmail: boolean;
};

export const ReservationView: React.FC<ReservationProps> = (props) => {
    const [error, setError] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [state, setState] = useState<
        | {
              concertInfo: IReservationInfoDto;
              desired: ISeatSelection[];
              blocks: {
                  blocks: IBlockDisplayInfo[];
                  possibilities?: ISeatDisplayInfo[][];
                  maxSeats?: number;
              };
          }
        | undefined
    >(undefined);

    const loadPlan = async () => {
        setLoading(true);
        try {
            const concertInfo = await service.getInfoForConcertAsync(props.concertId);
            const order = await service.getOrder(props.concertId, props.guid, props.verifyEmail);
            const desired: ISeatSelection[] = order.reservations.map((r) => {
                return {
                    seat: r.seat,
                    row: r.row,
                    blockId: r.blockId,
                    blockName: r?.blockName,
                    restriction: SeatRestriction.None,
                    state: SeatState.Desired,
                } as ISeatSelection;
            });
            const newPlanFactory = new PlanFactory(
                new RestrictionChecker(concertInfo.plan?.restrictions),
                concertInfo.reservations,
                concertInfo.plan,
            );
            if (concertInfo.plan?.restrictions?.seatsHaveToBeNextToEachOther) {
                const blocks = newPlanFactory.getPlanForOwnReservations(desired, desired.length);
                setState({
                    concertInfo,
                    desired,
                    blocks,
                });
            } else {
                const blocks = newPlanFactory.getBlocks();
                setState({
                    concertInfo,
                    desired,
                    blocks: {
                        blocks: blocks,
                        possibilities: undefined,
                        maxSeats: undefined,
                    },
                });
            }
        } catch (e) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadPlan();
    }, []);

    return (
        <MainContent>
            {isLoading && <LinearProgress></LinearProgress>}
            {!isLoading && error && (
                <Alert severity="error">
                    Der Sitzplan konnte wegen eines Netzwerkproblemes nicht überprüft werden!
                    <br />
                    <Button variant="contained" onClick={loadPlan}>
                        Nochmals versuchen.
                    </Button>
                </Alert>
            )}
            {!isLoading && !error && (
                <RootDiv>
                    {state && (
                        <>
                            <h1>Deine Sitzplatzreservation</h1>
                            <h2>{state.concertInfo.concert.title}</h2>
                            <h2 style={{ marginTop: '20px' }}>{state.concertInfo.plan.name}</h2>
                            <h3 style={{ marginBottom: '20px' }}>
                                Datum: {state.concertInfo.concert.date?.toFormat(DateFormat.Long)}
                            </h3>
                            {
                                <>
                                    <ReservationStepper
                                        nrOfSeats={state.desired.length}
                                        contactTracing={
                                            state.concertInfo.plan.restrictions?.seatsHaveToBeNextToEachOther ?? false
                                        }
                                    />
                                    <hr />
                                    <Plan
                                        plan={state.concertInfo.plan}
                                        blockInfos={state.blocks.blocks}
                                        nrOfDesiredSeats={state.desired.length}
                                        ownReservations={state.desired}
                                        reservations={state.concertInfo.reservations}
                                        onClickSeat={() => {
                                            /* do nothing */
                                        }}
                                        devMode={false}
                                    />
                                </>
                            }
                            {props.guid && (
                                <>
                                    <h2>Ticket</h2>
                                    <div style={{ marginTop: 20 }}>
                                        <Ticket concertId={props.concertId} guid={props.guid}></Ticket>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    <hr />
                </RootDiv>
            )}
        </MainContent>
    );
};
