import { DateFormat } from './date-format';
import { DateTime } from 'luxon';

export class DateTimeUtils {
    public static formatLong(someDate: DateTime | undefined): string {
        if (!someDate) {
            return '';
        }
        return someDate.toFormat(DateFormat.Long);
    }

    public static formatStore(someDate: DateTime | undefined): string {
        if (!someDate) {
            return '';
        }
        return someDate.toFormat(DateFormat.Store);
    }

    public static formatDbDateTime(someDate: DateTime | undefined): string {
        if (!someDate) {
            return '';
        }
        return someDate.toFormat(DateFormat.DbDateTime);
    }

    public static formatDbDate(someDate: DateTime | undefined): string {
        if (!someDate) {
            return '';
        }
        return someDate.toFormat(DateFormat.DbDate);
    }

    public static toStartOfDay(someDate: DateTime | undefined): DateTime | undefined {
        if (!someDate) {
            return undefined;
        }
        return someDate.startOf('day');
    }

    public static toEndOfDay(someDate: DateTime | undefined): DateTime | undefined {
        if (!someDate) {
            return undefined;
        }
        return someDate.endOf('day');
    }

    public static isAfterDay(someDate: DateTime | undefined, otherDate: DateTime | undefined): boolean {
        if (!someDate || !otherDate) {
            return false;
        }
        return someDate.startOf('day') > otherDate.startOf('day');
    }

    public static isBeforeDay(someDate: DateTime | undefined, otherDate: DateTime | undefined): boolean {
        if (!someDate || !otherDate) {
            return false;
        }
        return someDate.startOf('day') < otherDate.startOf('day');
    }

    public static isSameDay(someDate: DateTime | undefined, otherDate: DateTime | undefined): boolean {
        if (!someDate || !otherDate) {
            return false;
        }
        return someDate.startOf('day') === otherDate.startOf('day');
    }

    public static isSameDayOrAfter(someDate: DateTime | undefined, otherDate: DateTime | undefined): boolean {
        if (!someDate || !otherDate) {
            return false;
        }
        return someDate.startOf('day') >= otherDate.startOf('day');
    }

    public static isSameDayOrBefore(someDate: DateTime | undefined, otherDate: DateTime | undefined): boolean {
        if (!someDate || !otherDate) {
            return false;
        }
        return someDate.startOf('day') <= otherDate.startOf('day');
    }

    public static isToday(someDate: DateTime | undefined): boolean {
        const now = DateTime.local();
        return DateTimeUtils.isSameDay(someDate, now);
    }

    public static isAfterToday(someDate: DateTime | undefined): boolean {
        const now = DateTime.local();
        return DateTimeUtils.isAfterDay(someDate, now);
    }

    public static isBeforeToday(someDate: DateTime | undefined): boolean {
        const now = DateTime.local();
        return DateTimeUtils.isBeforeDay(someDate, now);
    }

    public static isTodayOrAfterToday(someDate: DateTime | undefined): boolean {
        const now = DateTime.local();
        return DateTimeUtils.isSameDayOrAfter(someDate, now);
    }

    public static isTodayOrBeforeToday(someDate: DateTime | undefined): boolean {
        const now = DateTime.local();
        return DateTimeUtils.isSameDayOrBefore(someDate, now);
    }

    public static isInThePast(someDate: DateTime | undefined): boolean {
        if (!someDate) {
            return false;
        }
        const now = DateTime.local();
        return someDate < now;
    }

    public static isInTheFuture(someDate: DateTime | undefined): boolean {
        if (!someDate) {
            return false;
        }

        const now = DateTime.local();
        return someDate > now;
    }

    public static compareDayTo(someDate: DateTime | undefined, otherDate: DateTime | undefined): number {
        if (!someDate && !otherDate) {
            return 0;
        }

        if (!someDate) {
            return -1;
        }

        if (!otherDate) {
            return 1;
        }

        const first = someDate.startOf('day');
        const second = otherDate.startOf('day');
        if (first === second) {
            return 0;
        }
        return first < second ? -1 : 1;
    }

    public static compareTo(someDate: DateTime | undefined, otherDate: DateTime | undefined): number {
        if (!someDate && !otherDate) {
            return 0;
        }

        if (!someDate) {
            return -1;
        }

        if (!otherDate) {
            return 1;
        }

        if (someDate === otherDate) {
            return 0;
        }
        return someDate < otherDate ? -1 : 1;
    }

    public static daysLater(someDate: DateTime | undefined, number: number): DateTime | undefined {
        if (!someDate) {
            return undefined;
        }
        return someDate.startOf('day').plus({ days: number });
    }

    public static daysEarlier(someDate: DateTime | undefined, number: number): DateTime | undefined {
        if (!someDate) {
            return undefined;
        }
        return someDate.startOf('day').minus({ days: number });
    }

    public static withTime(
        someDate: DateTime | undefined,
        hours: number | string,
        minutes?: number,
        seconds?: number,
    ): DateTime | undefined {
        if (!someDate) {
            return undefined;
        }

        if (typeof hours === 'string') {
            const split = hours.split(':');
            hours = parseInt(split[0]);
            if (split[1]) {
                minutes = parseInt(split[1]);
            }
            if (split[2]) {
                seconds = parseInt(split[2]);
            }
        }

        if (!hours) {
            hours = 0;
        }
        if (!minutes) {
            minutes = 0;
        }
        if (!seconds) {
            seconds = 0;
        }
        return someDate.set({ hour: hours, minute: minutes, second: seconds });
    }
}
