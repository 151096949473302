import { Grid, Typography } from '@mui/material';
import { IOrderDataDto, IPlanDto } from '../../../clients/services';
import { ReservationService } from '../../../services';
import { useState, useEffect } from 'react';
import { OptionsService } from '../../../services/price-calculator';
import { DateTimeUtils } from '../../../helpers';

const service = new ReservationService();

export const OrderInfo = (props: { order: IOrderDataDto }) => {
    const { order } = props;

    const [plan, setPlan] = useState<IPlanDto | null>(null);

    useEffect(() => {
        const loadPlan = async () => {
            if (order.concert.planId) {
                const newLoadedPlan = await service.getPlanAsync(order.concert.planId);
                setPlan(newLoadedPlan);
            }
        };

        loadPlan();
    }, [order]);

    const optionsService = new OptionsService(props.order.concert);

    const rabatt = optionsService.getReduction(order.reservations.length);
    return (
        <Typography>
            {' '}
            <Grid container>
                <Grid item xs={12}>
                    <b>Konzert:</b> {order.concert.title}
                    <br />
                    {order.concert.location && (
                        <>
                            <b>Ort:</b> {order.concert.location.name}, {order.concert.location.city}
                            <br />
                        </>
                    )}
                    <b>Datum:</b> {order.concert.date ? DateTimeUtils.formatLong(order.concert.date) : 'Unbekannt'}
                    <br />
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <div style={{ fontWeight: 'bold', fontSize: 20 }}>Person</div>
                </Grid>
                <Grid item xs={12}>
                    {order.person.firstName} {order.person.lastName}
                    <br />
                    {order.person.street} <br />
                    {order.person.zip} {order.person.city}
                    <br />
                    {order.person.tel}
                    <br />
                    <a href={`mailto:${order.person.email}`}>{order.person.email}</a>{' '}
                </Grid>
                <Grid item xs={12}>
                    <div style={{ fontWeight: 'bold', fontSize: 20, marginTop: 20 }}>Bestellung</div>
                    <b>Bestelldatum:</b> {DateTimeUtils.formatLong(order.dateCreated)}{' '}
                    {order.dateCreated.toFormat('HH:mm')}
                    <br />
                    <b>Anzahl Plätze:</b> {order.reservations.length}
                    <br />
                    <b>Gesamtpreis:</b> CHF {optionsService.getFullPrice(order.reservations).toFixed(0)}.-{' '}
                    {rabatt?.reduction && rabatt.reduction !== 0 && <>({rabatt.reduction * 100}% Rabatt)</>}
                    <br /> <b>Status:</b>{' '}
                    {order.canceled ? (
                        <span style={{ color: 'yellow' }}>storniert</span>
                    ) : (
                        <>
                            {order.payed ? (
                                <span style={{ color: 'green' }}>bezahlt</span>
                            ) : (
                                <span style={{ color: 'red' }}>noch nicht bezahlt</span>
                            )}
                        </>
                    )}
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                    <div style={{ fontWeight: 'bold', fontSize: 20, marginTop: 20 }}>Sitzplatzreservationen</div>
                </Grid>

                {order.reservations.map((r, k) => (
                    <Grid key={k} item xs={12}>
                        {plan?.blocks?.find((b) => b.id === r.blockId)?.name ?? `Block ${r.blockId}`} - Reihe {r.row} -
                        Platz {r.seat} - CHF {optionsService.getTicketPrice(r, order.reservations.length)}
                        {r?.options && r.options.filter((o) => o.text).length > 1 && (
                            <ul>
                                {r.options.map((o) => (
                                    <li key={o.id}>{o.text}</li>
                                ))}
                            </ul>
                        )}
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/reservation/view/${order.concertId}/${order.guid}`}
                    >
                        -&gt; Sitzplan
                    </a>
                </Grid>
                <Grid item xs={12}>
                    <a target="_blank" rel="noopener noreferrer" href={`/ticket/${order.concertId}/${order.guid}`}>
                        -&gt; Ticket
                    </a>
                </Grid>
                {order.contacts && order.contacts.length > 0 && (
                    <>
                        <Grid item xs={12}>
                            <div style={{ fontWeight: 'bold', fontSize: 20, marginTop: 20 }}>Gemeldete Kontakte</div>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ marginLeft: 20 }}>
                                {order.concert.restrictionOptions?.registerNamesOnly ? (
                                    <ol>
                                        {order.contacts.map((c, k) => (
                                            <li key={k}>
                                                {c.firstName} {c.lastName}
                                            </li>
                                        ))}
                                    </ol>
                                ) : (
                                    <ol>
                                        {order.contacts.map((c, k) => (
                                            <li key={k}>
                                                {c.firstName} {c.lastName}
                                                <br />
                                                {c.street}
                                                <br />
                                                {c.zip} {c.city}
                                                <br />
                                                {c.country}
                                                <br />
                                                {c.tel}
                                            </li>
                                        ))}
                                    </ol>
                                )}
                            </div>
                        </Grid>
                    </>
                )}
            </Grid>
        </Typography>
    );
};
